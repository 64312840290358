.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem;
  position: relative;
  height: 80px;
}

.header a {
  text-decoration: none;
  color: var(--header-logo-color);
  cursor: pointer;
}

.header h2 {
  font-weight: 800;
  font-size: 2rem;
}

.header a span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#ff512f, #dd2476);
  -webkit-background-clip: text;
}

.header h4 {
  font-size: 1rem;
  font-weight: 700;
}

@media (max-width: 430px) {
  .header {
    padding: 1rem 1.4rem;
  }
}
