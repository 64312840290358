.result-div {
  width: 100%;
  border: var(--result-border);
  border-radius: 12px;
  background-color: var(--result-background-color);
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  text-align: center;
  font-size: 0.8rem;
  box-shadow: var(--result-box-shadow);
}

.result-div table {
  width: 100%;
  border-collapse: collapse; /* Ensures no double borders */
  margin-top: 1rem;
  background-color: transparent; /* Transparent table background */
}

.result-div th,
.result-div td {
  border: 1px solid var(--table-border-color, #dddddd24); /* Keeps border visible */
  padding: 8px;
  text-align: center;
  font-size: 0.8rem;
  background-color: transparent; /* Transparent cell background */
}

.result-div th {
  font-weight: bold;
  background-color: transparent; /* Transparent header background */
}

.result-div tr:nth-child(even) {
  background-color: transparent; /* No row alternation, stays transparent */
}

.result-div tr:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Subtle hover effect with transparency */
}

.result-div a {
  color: var(--link-color, #007bff); /* Link color */
  text-decoration: none;
}

.result-div a:hover {
  text-decoration: underline;
}

.thumb-div {
  border: var(--result-border);
  width: 320px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 5px;
}

.thumb-div img {
  width: 310px;
  height: 290px;
  border-radius: 5px;
  opacity: 0.9;
}

.result-div h3 {
  margin: 1rem 0rem;
  font-size: 1rem;
  color: var(--result-title-font-color);
}

.download-section table {
  background-color: var(--result-table-bg-color);
  border: var(--result-border);
  width: 100%;
  color: var(--result-table-font-color);
  border-radius: 5px;
}

.download-section th {
  padding-top: 1rem;
  text-transform: uppercase;
}

.download-section td {
  padding: 1.2rem;
  font-size: 0.8rem;
}

.download-section img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  opacity: 0.9;
}
.download-section a {
  padding: 0.5rem 1rem;
  background-color: #c50052d7;
  text-decoration: none;
  color: rgb(226, 226, 226);
  border-radius: 5px;
  cursor: pointer;
}
.download-section a:hover {
  background-color: #a10144;
}

@media (max-width: 560px) {
  .download-section th {
    padding-top: 0.8rem;
    font-size: 0.7rem;
  }
  .download-section td {
    padding: 0.8rem;
    font-size: 0.7rem;
  }
  .download-section a {
    background-color: #6f14d6;
    color: rgb(226, 226, 226);
  }
  .download-section a:hover {
    background-color: #5500c5a6;
  }
}

@media (max-width: 425px) {
  .result-div h3 {
    font-size: 0.8rem;
  }
  .download-section a {
    padding: 0.5rem 0.8rem;
    font-size: 0.7rem;
  }
  .thumb-div {
    width: 170px;
    height: 170px;
  }
  .thumb-div img {
    width: 160px;
    height: 160px;
  }
  .result-div th:nth-child(1), /* Target the first <th> */
  .result-div td:nth-child(1) { /* Target the first <td> in rows */
    display: none;
  }
  .result-div th:nth-child(3), /* Target the first <th> */
  .result-div td:nth-child(3) { /* Target the first <td> in rows */
    display: none;
  }
}

@media (max-width: 375px) {
  .download-section th {
    padding-top: 0.9rem;
  }

  .download-section td {
    padding: 0.9rem;
  }
  .download-section a {
    padding: 0.5rem 0.6rem;
    font-size: 0.6rem;
  }
  .result-div {
    padding: 1rem 0.4rem;
    margin-top: 2rem;
  }
  .result-div th:nth-child(1), /* Target the first <th> */
  .result-div td:nth-child(1) { /* Target the first <td> in rows */
    display: none;
  }
  .result-div th:nth-child(3), /* Target the first <th> */
  .result-div td:nth-child(3) { /* Target the first <td> in rows */
    display: none;
  }
}

@media (max-width: 299px) {
  .result-div {
    overflow: auto;
  }
}
