.error {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 12px;
  border: var(--error-border);
  margin-top: 2rem;
  background-color: var(--error-bg-color);
  box-shadow: 1px 1px 10px 10px #00000017;
  color: var(--error-font-color);
  font-size: 0.9rem;
  text-align: left;
  line-height: 1.5rem;
  text-transform: uppercase;
}

@media (max-width: 520px) {
  .error {
    font-size: 0.8rem;
    padding: 1.5rem;
  }
}
