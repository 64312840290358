.footer {
  width: 100%;
  height: 40px;
  margin: auto;
  padding: 3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem; /* Adjust the value as needed */
  gap: 10px;
  color: var(--footer-text-color);
}

.footer-store-dev {
  align-items: center;
  display: flex;
  justify-content: center;
  background: -webkit-linear-gradient(#ff512f, #dd2476);
  padding: 0.5rem;
  border-radius: 10px
}

.footer-store {
  align-items: center;
  display: flex;
  margin-left: 30px;
}

.footer img.small {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.footer img.large {
  width: 100px;
  height: 30px;
}

.footer span {
  font-size: 1.1rem;
  font-weight: 500;
}

@media (max-width: 450px) {
  .footer-store {
    align-items: center;
    display: flex;
    margin-left: 10px;
  }
  .footer img.large {
    width: 70px;
    height: 20px;
  }
}

