*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

[data-theme="dark"] {
  --background-color: rgb(15, 15, 15);
  --header-logo-color: white;
  --h1-font-color: white;
  --p-font-color: rgb(201, 201, 201);

  --input-background: #20212c;
  --input-text-color: rgb(223, 223, 223);
  --input-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.38);
  --input-placeholder-text-color: rgb(187, 187, 187);
  --input-button-background-color: #f30049ce;
  --input-button-hover-background-color: #a50037;

  --result-background-color: #00000085;
  --result-border: 1.5px solid #1e1f2471;
  --result-box-shadow: 1px 1px 10px 10px #0000002c;
  --result-title-font-color: rgb(192, 192, 192);
  --result-table-font-color: rgb(201, 201, 201);
  --result-table-bg-color: #191a27;

  --loader-bg-color: #272935;
  --loader-border: 1.2px solid #5e6076;
  --loader-box-shadow: 1px 1px 20px 10px #00000000;
  --loader-progress-bg-color: #dd0155c9;
  --loader-progress-box-shadow: 1px 1px 20px 10px rgba(0, 0, 0, 0.295);
  --loader-animated-color: #fff;

  --error-bg-color: #262733;
  --error-border: 1.2px solid #0000001a;
  --error-font-color: rgb(233, 40, 66);

  --footer-text-color: rgb(223, 223, 223);
}

[data-theme="light"] {
  --background-color: rgb(255, 255, 255);
  --h1-font-color: #1e1e1e;
  --p-font-color: #5c5c5c;
  --header-logo-color: #2c2c2c;

  --input-background: rgb(255, 255, 255);
  --input-text-color: rgb(34, 34, 34);
  --input-box-shadow: 0px 0px 20px 1px rgb(138 138 138 / 38%);
  --input-placeholder-text-color: rgb(121, 121, 121);
  --input-button-background-color: #1477e9e7;
  --input-button-hover-background-color: rgba(15, 105, 207, 0.906);

  --result-background-color: #rgb(255, 255, 255);
  --result-border: 2px solid #82839142;
  --result-box-shadow: 0px 0px 8px 4px #22222227;
  --result-title-font-color: rgb(34, 34, 34);
  --result-table-font-color: rgb(34, 34, 34);
  --result-table-bg-color: #e6e6e68c;

  --loader-bg-color: #ffffff;
  --loader-border: 1.2px solid #b8b8b86c;
  --loader-box-shadow: 0px 0px 5px 5px #00000017;
  --loader-progress-bg-color: #0327f7c9;
  --loader-progress-box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.199);
  --loader-animated-color: #0755ff;

  --error-bg-color: #ffffff;
  --error-border: 1.2px solid #0000001a;
  --error-font-color: rgb(255, 80, 80);

  --footer-text-color: rgb(70, 70, 70);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  min-height: 100vh;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
