.index-div {
  width: 80%;
  text-align: center;
  padding: 1.2rem;
  position: relative;
  margin: 2rem auto;
}

.index-div h1 {
  font-size: 3.6rem;
  color: var(--h1-font-color);
}

.index-div h2, h3 {
  font-size: 1.5rem;
  padding: 16px;
  color: var(--h1-font-color);
}

.head-two {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#ff512f, #dd2476);
  -webkit-background-clip: text;
}

.head-three {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#25ff92, #24afff);
  -webkit-background-clip: text;
}

.index-div p {
  color: var(--p-font-color);
  font-size: 0.9rem;
  margin: 1rem auto;
  text-align: left;
  width: 80%;
  line-height: 24px;
  font-weight: 400;
}

.index-div img {
  width: 55px;
  height: 55px;
  position: absolute;
}

@keyframes ytMovement {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(10px, -10px) scale(1.1);
  }
  50% {
    transform: translate(-10px, 10px) scale(0.9);
  }
  75% {
    transform: translate(10px, 10px) scale(1.05);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes fbMovement {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(-10px, 10px) scale(1.1);
  }
  50% {
    transform: translate(10px, -10px) scale(0.9);
  }
  75% {
    transform: translate(-10px, -10px) scale(1.05);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes instaMovement {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(10px, 10px) scale(1.1);
  }
  50% {
    transform: translate(-10px, -10px) scale(0.9);
  }
  75% {
    transform: translate(10px, -10px) scale(1.05);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes xMovement {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(-10px, -10px) scale(1.1);
  }
  50% {
    transform: translate(10px, 10px) scale(0.9);
  }
  75% {
    transform: translate(-10px, 10px) scale(1.05);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

.yt-logo {
  animation: ytMovement 5s infinite ease-in-out;
  top: 0%;
  right: 10%;
  filter: drop-shadow(5px 5px 25px #f5101f);
}

.fb-logo {
  animation: fbMovement 5s infinite ease-in-out;
  bottom: 0px;
  right: 15%;
  filter: drop-shadow(5px 5px 25px #1ca4ff);
}

.insta-logo {
  animation: instaMovement 5s infinite ease-in-out;
  bottom: 10%;
  left: 12%;
  filter: drop-shadow(5px 5px 25px #906bff);
}

.x-logo {
  animation: xMovement 5s infinite ease-in-out;
  top: 5%;
  left: 10%;
  filter: drop-shadow(5px 5px 25px #118be4);
}

@media (max-width: 1520px) {
  .index-div {
    width: 80%;
  }

  .index-div p {
    color: var(--p-font-color);
    font-size: 0.9rem;
    margin: 1rem auto;
    text-align: left;
    width: 80%;
    line-height: 24px;
    font-weight: 400;
  }
}

@media (max-width: 1350px) {
  .index-div {
    width: 90%;
  }

  .index-div p {
    color: var(--p-font-color);
    font-size: 0.9rem;
    margin: 1rem auto;
    text-align: left;
    width: 80%;
    line-height: 24px;
    font-weight: 400;
  }
}

@media (max-width: 1200px) {
  .index-div {
    width: 100%;
  }

  .index-div p {
    color: var(--p-font-color);
    font-size: 0.9rem;
    margin: 1rem auto;
    text-align: left;
    width: 80%;
    line-height: 24px;
    font-weight: 400;
  }

  .yt-logo {
    right: 5%;
  }

  .fb-logo {
    right: 10%;
  }

  .insta-logo {
    left: 8%;
  }

  .x-logo {
    left: 5%;
  }
}

@media (max-width: 850px) {
  .index-div {
    margin-top: 3rem;
    padding: 0px 0px;
  }

  .index-div h2, h3 {
    font-size: 1.5rem;
    color: var(--h1-font-color);
  }

  .index-div p {
    color: var(--p-font-color);
    font-size: 0.9rem;
    margin: 1rem auto;
    text-align: left;
    width: 80%;
    line-height: 24px;
    font-weight: 400;
  }

  .index-div img {
    width: 45px;
    height: 45px;
  }

  .yt-logo {
    top: -20%;
    right: 5%;
  }

  .fb-logo {
    right: 6%;
    bottom: -15%;
  }

  .insta-logo {
    left: 8%;
    bottom: -10%;
  }

  .x-logo {
    top: -18%;
    left: 5%;
  }
}

@media (max-width: 670px) {
  .index-div {
    margin-bottom: 4rem;
  }

  .index-div h2, h3 {
    font-size: 1.5rem;
    color: var(--h1-font-color);
  }

  .index-div p {
    color: var(--p-font-color);
    font-size: 0.9rem;
    margin: 1rem auto;
    text-align: left;
    width: 80%;
    line-height: 24px;
    font-weight: 400;
  }

  .index-div p {
    color: var(--p-font-color);
    font-size: 0.9rem;
    margin: 1rem auto;
    text-align: left;
    width: 80%;
    line-height: 24px;
    font-weight: 400;
  }

  .yt-logo {
    top: -30%;
    right: 5%;
  }

  .fb-logo {
    bottom: -30%;
    right: 6%;
  }

  .insta-logo {
    left: 5%;
    bottom: -25%;
  }

  .x-logo {
    top: -25%;
    left: 5%;
  }
}

@media (max-width: 540px) {
  .index-div h2, h3  {
    font-size: 1.5rem;
    color: var(--h1-font-color);
  }

  .logo-div img {
    width: 42px;
    height: 42px;
  }

  .yt-logo {
    top: -15%;
    right: 8%;
  }

  .fb-logo {
    bottom: -18%;
    right: 9%;
  }

  .insta-logo {
    left: 9%;
    bottom: -20%;
  }

  .x-logo {
    top: -20%;
    left: 8%;
  }
}

@media (max-width: 450px) {
  .index-div {
    margin-bottom: 5rem;
  }

  .index-div h2, h3  {
    font-size: 1.5rem;
    color: var(--h1-font-color);
  }
  .insta-logo {
    bottom: -18%;
  }
}

@media (max-width: 350px) {
  .index-div img {
    width: 40px;
    height: 40px;
  }
  .index-div h2, h3 {
    font-size: 1.5rem;
    color: var(--h1-font-color);
  }
}

@media (max-width: 290px) {
  .logo-div img {
    width: 35px;
    height: 35px;
  }
  .insta-logo {
    left: 9%;
    bottom: -14%;
  }

  .x-logo {
    top: -10%;
    left: 8%;
  }
  .fb-logo {
    bottom: -10%;
    right: 5%;
  }
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.index-div ul {
  list-style-type: none;
  text-align: left;
  width: 80%;
  margin: 1rem auto;
}

.index-div ul li {
  font-size: 1rem;
  color: var(--p-font-color);
  text-align: left;
  padding: 4px;
}

.index-div ul li::before {
  content: '•';
  color: var(--h1-font-color);
  font-size: 1.2rem;
  margin-right: 8px;
  padding: 4px;
}

